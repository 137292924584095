import React, { useState, useEffect } from 'react';
import '../style/popup.css';

function Popup({ popup }) {
  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    const updateMousePosition = ev => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    useEffect(() => {
      window.addEventListener('mousemove', updateMousePosition);

      return () => window.removeEventListener('mousemove', updateMousePosition);
    }, []);

    return mousePosition;
  };

  const { x, y } = useMousePosition();
  const hasMovedCursor = typeof x === 'number' && typeof y === 'number';

  return (
    <div className='heropop'>
      <div className='popupclose'>
        <button className='popupbutton' onClick={() => popup()}>
          {hasMovedCursor ? (
            <img
              src='./img/logo/pause.png'
              className='pauseimg'
              alt='icon'
              style={{
                left: x,
                top: y,
              }}
            ></img>
          ) : null}
        </button>
      </div>

      <video className='videosContent' autoPlay>
        <source
          src='https://player.vimeo.com/progressive_redirect/playback/659752784/rendition/1080p?loc=external&signature=81ff0f408dc05613cddffd3581d1dd7cfd23dff61f37d6fe7ab08feff36168ad'
          type='video/mp4'
        ></source>
      </video>
    </div>
  );
}

export default Popup;
