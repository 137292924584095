import React from 'react';
import '../style/footer.css';

function Footer() {
  return (
    <div className='footer'>
      <div className='footercontents'>
        <div className='footerTitle'>
          <p>Little Buddy &amp; Co 2022</p>
        </div>
        <div className='socialImg'>
          <a
            href='https://www.linkedin.com/company/75428945/'
            target='_blank'
            rel='noopener, noreferrer'
          >
            <img
              className='inLogo'
              src='./img/logo/inLogo.png'
              alt='photoAlt'
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
