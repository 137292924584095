import React from 'react';
import '../style/nav.css';

function Nav() {
  // const [menu, setMenu] = useState(false);
  // const mobileMenu = useRef();

  // const toggleMenu = () => {
  //   setMenu(!menu);
  //   if (!menu) {
  //     gsap.to(mobileMenu.current, {
  //       top: 0,
  //       left: 0,
  //       opacity: 1,
  //       borderRadius: 0,
  //       duration: 0.5,
  //       ease: 'power4.in',
  //     });
  //   } else {
  //     gsap.to(mobileMenu.current, {
  //       top: '-90%',
  //       left: '90%',
  //       borderRadius: '50%',
  //       ease: 'power4.out',
  //       duration: 0.8,
  //     });
  //   }
  // };

  return (
    <div className='header'>
      <div className='navbar'>
        <div className='navLogo'>
          <img
            className='logoImg'
            src='./img/logo/LittleBuddyLogo.svg'
            alt='head'
          />
        </div>
        {/* <div className='burgerimg'>
          <button className='btnBurger' onClick={toggleMenu}>
            {!menu ? (
              <img
                className='burgerIcon'
                src='./img/logo/burgerIcon.png'
                alt='head2'
              />
            ) : (
              <img
                className='burgerIcon'
                src='./img/logo/burgerIcon-open.png'
                alt='head2'
              />
            )}
          </button>
        </div> */}
      </div>
      {/* <div className='navMenu' ref={mobileMenu}>
        <ul>
          <li>
            <button className='ListBtn'>Amtrak How-To</button>
          </li>
          <li>
            <button className='ListBtn'>Disney&apos;s Outfit of the Day</button>
          </li>
          <li>
            <button className='ListBtn'>Wyndham Resort</button>
          </li>
          <li>
            <button className='ListBtn'>
              Carl&apos;s Jr. 100 Days of Summer
            </button>
          </li>
          <li>
            <button className='ListBtn'>One Day at Disney</button>
          </li>
          <li>
            <button className='ListBtn'>Cannodale Synapse Launch</button>
          </li>
          <li>
            <button className='ListBtn'>Interactive Social</button>
          </li>
          <li>
            <button className='ListBtn'>Sage Therapeutics</button>
          </li>
        </ul>
      </div> */}
    </div>
  );
}

export default Nav;
