import React from 'react';
import '../style/detail.css';

const Detail = () => {
  return (
    <div className='detailcontainer'>
      <div className='detailinner'>
        <div className='detaillogo'>
          <img className='logoImg' src='./img/darklogo.png' alt='head' />
        </div>
        <div className='innerpage'>
          <div className='row'>
            <div className='col'>
              <div className='image'>
                <img src='./img/agency.jpg' alt='Agency' />
                <div className='innertext'>
                  <h2>Agency Reel</h2>
                  <p>
                    Selects from Disney Parks, Carl's Jr, Amtrak, US Marines,
                    Universal Studios, Disney Publishing, LG and Cannondale
                  </p>
                </div>
              </div>
            </div>
            <div
              className='col'
              style={{ backgroundImage: 'url(./img/agency.jpg)' }}
            >
              image 1
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
