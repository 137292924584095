import React from 'react';
import '../style/home.css';
import Footer from './Footer';
import Nav from './Nav';

function Home({ popup, password }) {
  return (
    <>
      <Nav />
      <div className='container'>
        <div className='hero'>
          <div className='herocontent'>
            <div className='herotitle'>
              <p>
                We help brands of every size do more with their&nbsp;
                <br className='brRemove' />
                budgets through a remote creative network.
              </p>
            </div>
            <div className='herobottom'>
              <div className='buttons'>
                <button className='sectionBtn' onClick={() => popup()}>
                  <img
                    className='imgArrow'
                    src='./img/logo/heroArrow.svg'
                    alt='photoAlt'
                  />
                  <span className='textBtn'>View reel</span>
                </button>
                {/* <button className='sectionBtn'>
              <img
                className='imgArrow'
                  src='./img/logo/heroArrow.svg'
                  alt='photoAlt'    />
                <span className='textBtn'>View our mission</span>
              </button> */}
              </div>
            </div>
          </div>
        </div>

        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/584501065/rendition/1080p?loc=external&signature=27d351e0d85a3275877b7a2fdc0e04171789dfcd1483f7101188ca8862544ed9'
              type='video/mp4'
            ></source>
          </video>
          <div className='sectionVidContent'>
            <p className='sectionTitle'>Amtrak How-To</p>
            <p className='sectionDiscription'>
              Educating the next generation of riders in a multi-year campaign.
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/584502321/rendition/1080p?loc=external&signature=f4d5d86dbe5921fb7cfb29c9ae16861f11a26d2c705382c491ad6d35f6c1c168'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>Disney&apos;s Outfit of the Day</p>
            <p className='sectionDiscription'>
              A stop motion content series to inspire your next park visit.
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/584501593/rendition/1080p?loc=external&signature=af5726c388ea770775c07b3be75ee8e7b5289f134c32e9d71bfe36ad43401901'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>Wyndham Resort</p>
            <p className='sectionDiscription'>
              Rebranding Margaritaville Vacation Club
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/585087591/rendition/1080p?loc=external&signature=c0a3d444a5ba02f239c8617fc7df34b55cad8a0857a119e72a10052bd08f5a76'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>Carl&apos;s Jr. 100 Days of Summer</p>
            <p className='sectionDiscription'>
              A whimsical summer social campaign.
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/584501820/rendition/1080p?loc=external&signature=a4bedea144eae28e432708973095a3c474895b50a2124feb5b72cc46d48b6549'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>One Day at Disney</p>
            <p className='sectionDiscription'>
              A campaign that launched a book to the Top 50 on Amazon.com
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/584501939/rendition/720p?loc=external&signature=f4b5a18551f05620a91ae558d26bfc4cf2b8f59f812afec36c195ed7d3b6075d'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>Cannondale Synapse Launch</p>
            <p className='sectionDiscription'>
              Campaign broadcast for a revolutionary bike platform.
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/595519130/rendition/1080p?loc=external&signature=a004cff6c26854263a76ced68703c7afb0873303097ef9139e84e63b31e49d8c'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>Interactive Social</p>
            <p className='sectionDiscription'>
              Driving brand love and engagement through AR and 360.
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <div className='section'>
          <video
            className='videosContent'
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            preload='auto'
          >
            <source
              src='https://player.vimeo.com/progressive_redirect/playback/584502062/rendition/1080p?loc=external&signature=1bcaa296ede015819a3633698f97190babfd14ef4226ce88231726c1ed3780d9'
              type='video/mp4'
            ></source>
          </video>

          <div className='sectionVidContent'>
            <p className='sectionTitle'>Amtrak Top Stops</p>
            <p className='sectionDiscription'>
              A multi-year campaign to capture unique travel experiences.
            </p>
            <button className='sectionArrow' onClick={() => password()}>
              <img
                className='imgArrowSec'
                src='./img/logo/arrow1.svg'
                alt='photoAlt'
              />
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
