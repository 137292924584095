import './App.css';
import React, { useState } from 'react';
import Home from './components/Home';
import Popup from './components/Popup';
import Password from './components/Password';
import Detail from './components/Detail';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  const [isPopup, setIsPopup] = useState(false);
  const [isPassword, setIsPassword] = useState(false);

  const togglePopup = () => {
    setIsPopup(!isPopup);
  };
  const togglePassword = () => {
    setIsPassword(!isPassword);
  };
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={<Home popup={togglePopup} password={togglePassword} />}
          />
          <Route path='/detail' element={<Detail />} />
        </Routes>
        {isPopup ? <Popup popup={togglePopup} /> : null}
        {isPassword ? <Password password={togglePassword} /> : null}
      </BrowserRouter>
    </div>
  );
}

export default App;
