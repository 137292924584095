import React, { useState } from 'react';
import '../style/popup.css';
import { useNavigate } from 'react-router-dom';

const Password = ({ password }) => {
  const pw = 'asdf1234';
  const [pass, setPass] = useState();
  const navigate = useNavigate();

  const verify = e => {
    e.preventDefault();
    if (pw === pass) {
      navigate('/detail', { replace: true });
      password();
    } else {
      console.log('plesae enter correct password');
    }
  };

  const goback = e => {
    e.preventDefault();
    password();
  };

  return (
    <div className='heropop'>
      <div className='password'>
        <div className='passtxt'>
          <div className='input'>
            <input
              className='inputfield'
              value={pass}
              onChange={e => setPass(e.target.value)}
              placeholder={'Enter Password...'}
            ></input>
            <button onClick={verify}>Submit</button>
          </div>
          <div className='backbtn'>
            <button onClick={goback}>back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
